import styled, { css } from "styled-components";
import Img from "gatsby-image";

import ReactTypingEffect from "../../../../../../../vendor/react-typing-effect/src/lib";
import { device } from "../../../../../../utils/breakpoints";
import { fadeIn, fadeOut } from "../../../../../../utils/animations";
import {
  H0,
  H0_LAPTOP,
  H3,
  H6,
  TEXT_BODY_2,
} from "../../../../../../assets/styles/typography";

interface PageProps {
  animation?: string;
}

export const PageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  padding: 88px 118px 40px 88px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: #ffffff;
  animation: ${(props: PageProps) =>
    props.animation
      ? css`
          ${fadeOut} 300ms linear
        `
      : css`
          ${fadeIn} 300ms linear
        `};
  animation-fill-mode: forwards;

  @media ${device.computer} {
    height: auto;
    padding: 128px 120px 120px 120px;
    display: grid;
    grid-template-columns: repeat(12, 64px);
    grid-column-gap: 32px;
  }

  @media ${device.desktop} {
    grid-template-columns: repeat(12, 110px);
  } ;
`;

export const StyledTypingEffect = styled(ReactTypingEffect)`
  display: block;
  color: #013cff;
  height: 180px;
  font-size: 194px;
  line-height: 152px;
  font-weight: 800;
  white-space: nowrap;

  span {
    font-weight: initial;
    font-size: 154px;
    transform: translateY(-20px);
  }

  @media ${device.tablet}, ${device.tabletLandscape} {
    margin-top: 24px;
  }

  @media ${device.laptop} {
    ${H0_LAPTOP}
    height: 300px;

    span {
      font-weight: initial;
      font-size: 232px;
      transform: translateY(-32px);
    }
  }

  @media ${device.laptopL} {
    height: 312px;

    span {
      font-weight: initial;
      font-size: 300px;
      transform: translateY(-32px);
    }
  }

  @media ${device.desktop} {
    ${H0}
    height: 400px;

    span {
      font-weight: initial;
      font-size: 360px;
      transform: translateY(-52px);
    }
  } ;
`;

export const DescriptionWrapper = styled.div`
  width: 60%;
  padding: 48px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  color: #151515;

  & > * {
    opacity: 0;
    animation: ${fadeIn} 0.6s linear;
    animation-delay: 0.6s;
    animation-fill-mode: forwards;

    &:nth-of-type(2) {
      animation-delay: 1.2s;
    }
  }

  @media ${device.tablet} {
    width: 100%;
    padding: 24px;
    flex-direction: column;
  }

  @media ${device.tabletLandscape} {
    width: 100%;
    padding: 24px;
    justify-content: flex-start;
  }

  @media ${device.computer} {
    padding: 0;
    grid-column: 1 / 7;
    width: auto;
    margin-top: 69px;
  }

  @media ${device.desktop} {
    margin-top: 80px;
  } ;
`;

interface DescriptionProps {
  color?: string;
}

export const Description = styled.div`
  ${TEXT_BODY_2}
  color: ${(props: DescriptionProps) => props.color || "#151515"};
  white-space: pre-line;

  @media ${device.computer} {
    &:nth-of-type(1) {
      grid-column: 1 / 4;
      margin-left: 32px;
    }

    &:nth-of-type(2) {
      grid-column: 4 / 6;
    }
  } ;
`;

export const VerticalDivider = styled.hr`
  display: block;
  min-width: 1px;
  height: 100%;
  min-height: 24vh;
  margin: 0 42px;
  background: #eaeaea;
  opacity: 0;
  animation: ${fadeIn} 0.6s linear;
  animation-delay: 0.9s;
  animation-fill-mode: forwards;

  @media ${device.tablet}, ${device.tabletLandscape} {
    min-height: 144px;
    margin: 0;
  }

  @media ${device.computer} {
    margin: 0 32px 0 64px;
  }

  @media ${device.desktop} {
    min-height: auto;
    height: 164px;
  } ;
`;

export const PartnersPageWrapper = styled.div`
  position: relative;
  flex: 1;
  min-height: 100vh;
  padding: 80px 160px 12px 120px;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 2.4rem auto auto;
  background: #013cff;
  animation: ${(props: PageProps) =>
    props.animation
      ? css`
          ${fadeOut} 300ms linear
        `
      : css`
          ${fadeIn} 300ms linear
        `};
  animation-fill-mode: forwards;

  @media ${device.tablet}, ${device.tabletLandscape} {
    padding: 88px 118px 12px 88px;
    display: flex;
    flex-direction: column;
  }

  @media ${device.computer} {
    padding: 128px 120px 120px 120px;
    grid-template-rows: 60px, repeat(5, 264px);
    grid-template-columns: repeat(12, 64px);
    grid-column-gap: 32px;
    grid-row-gap: 64px;
  }

  @media ${device.desktop} {
    grid-template-columns: repeat(12, 110px);
    grid-template-rows: auto;
    grid-row-gap: 0;
  } ;
`;

export const TeamMemberInfoWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding-bottom: 52px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @media ${device.computer} {
    padding-bottom: 0;

    &:nth-of-type(2) {
      grid-row: 2 / 3;
      grid-column: 1 / 12;
    }

    &:nth-of-type(3) {
      grid-row: 3 / 4;
      grid-column: 1 / 12;
    }

    &:nth-of-type(4) {
      grid-row: 4 / 5;
      grid-column: 1 / 12;
    }

    &:nth-of-type(5) {
      grid-row: 5 / 6;
      grid-column: 1 / 12;
    }
  }

  @media ${device.desktop} {
    align-items: center;

    &:nth-of-type(2) {
      grid-row: 2 / 3;
      grid-column: 1 / 6;
    }

    &:nth-of-type(3) {
      grid-row: 2 / 3;
      grid-column: 7 / 12;
    }

    &:nth-of-type(4) {
      grid-row: 3 / 4;
      grid-column: 1 / 6;
    }

    &:nth-of-type(5) {
      grid-row: 3 / 4;
      grid-column: 7 / 12;
    }
  }
`;

export const ProfilePicture = styled(Img)`
  width: 14vw;
  height: 14vw;
  margin: 0;

  img {
    right: 0;
    margin: 0 auto;
    width: auto !important;
    object-fit: cover !important;
    border-radius: 50%;
  }

  @media ${device.tablet} {
    width: calc(28vh + 40px);
    height: 28vw;
    margin: 0 32px 0 8px;
  }

  @media ${device.tabletLandscape} {
    width: calc(23vw + 40px);
    height: 23vw;
    margin: 0 32px 0 8px;
  }

  @media ${device.laptopL} {
    width: 18vw;
    height: 18vw;
    margin: 40px 0;
  }

  @media ${device.desktop} {
    width: 13vw;
    height: 13vw;
  } ;
`;

export const Column = styled.div`
  width: calc(100% - 14vw);
  height: 100%;
  padding: 0 2vw;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media ${device.laptopL} {
    width: calc(100% - 18vw);
  } ;
`;

export const Title = styled.div`
  grid-column-end: 2 span;
  margin: 0;
  margin-bottom: 56px;
  font-size: 22px;
  line-height: 26px;
  font-weight: 700;
  color: #ffffff;

  @media ${device.computer} {
    ${H3}
    grid-column: 1 / 10;
    grid-row: 1 / 2;
  } ;
`;

export const TeamMemberTitle = styled.h1`
  grid-column-end: 2 span;
  padding-bottom: 24px;
  margin: 0;
  color: #ffffff;
  font-size: 32px;
  line-height: 38px;
  font-weight: 700;

  @media ${device.computer} {
    ${H6}
    padding-bottom: 16px;
  }

  @media ${device.desktop} {
    ${H6}
    width: 90%;
  } ;
`;

export const TeamMemberDescription = styled.div`
  ${TEXT_BODY_2}
  color: ${(props: DescriptionProps) => props.color || "#151515"};
  white-space: pre-line;

  @media ${device.computer} {
    width: 650px;
  }

  @media ${device.desktop} {
    width: 395px;
  } ;
`;

export const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 344px;
  margin: 48px 0;

  &:first-of-type {
    margin-right: 24px;
  }

  @media ${device.tablet} {
    &:first-of-type {
      margin-left: 0;
      margin-right: auto;
    }

    &:last-of-type {
      margin-left: auto;
      margin-right: 0;
    }
  }

  & > *:nth-child(2) {
    width: 320px;
  }
`;
