import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { useTranslation } from "react-i18next";

import {
  PageWrapper,
  StyledTypingEffect,
  DescriptionWrapper,
  Description,
  TeamMemberDescription,
  VerticalDivider,
  PartnersPageWrapper,
  TeamMemberInfoWrapper,
  Column,
  Title,
  ProfilePicture,
  TeamMemberTitle,
  RowWrapper,
} from "./style";
import { PageChange } from "../../../../../common/Scroll";
import Breakpoint from "../../../../../common/Breakpoint";
import {
  FluidImageNodesType,
  TeamMemberType,
} from "../../../../../../utils/types";
import { fixConjunctions } from "../../../../../../utils/dropTheWord";
import { PageTitle } from "../../../../../common/PageTitle";
interface ImagesType {
  members?: FluidImageNodesType;
}

interface TeamProps {
  pageChange: PageChange;
}

const TeamPage: React.FC<TeamProps> = ({ pageChange }) => {
  const { t } = useTranslation("rnd");
  const data = useStaticQuery(imagesQuery) as ImagesType;

  return (
    <>
      <PageTitle subtitle={t(`our_team_title`)} />
      <PageWrapper animation={pageChange}>
        <StyledTypingEffect
          text={["Our", "team"]}
          speed={200}
          typingDelay={300}
          eraseDelay={700}
        />
        <Breakpoint device={["tablet", "tabletLandscape"]}>
          <DescriptionWrapper>
            <RowWrapper>
              <VerticalDivider />
              <Description
                dangerouslySetInnerHTML={{
                  __html: fixConjunctions(t(`team_description_firstParagraph`)),
                }}
              />
            </RowWrapper>
            <RowWrapper>
              <VerticalDivider />
              <Description
                dangerouslySetInnerHTML={{
                  __html: fixConjunctions(
                    t(`team_description_secondParagraph`)
                  ),
                }}
              />
            </RowWrapper>
          </DescriptionWrapper>
        </Breakpoint>
        <Breakpoint device="computer">
          <DescriptionWrapper>
            <Description
              dangerouslySetInnerHTML={{
                __html: fixConjunctions(t(`team_description_firstParagraph`)),
              }}
            />
            <VerticalDivider />
            <Description
              dangerouslySetInnerHTML={{
                __html: fixConjunctions(t(`team_description_secondParagraph`)),
              }}
            />
          </DescriptionWrapper>
        </Breakpoint>
      </PageWrapper>

      <PartnersPageWrapper animation={pageChange}>
        <Title>{t(`team_partners_title`)}</Title>
        {(t(`team_partners`) as unknown as TeamMemberType[]).map(
          ({ name, description }, index) => {
            const profilePicture =
              data.members &&
              data.members.nodes.filter(
                img => img.name.toLowerCase() === name.toLowerCase()
              )[0];

            return (
              <TeamMemberInfoWrapper key={index}>
                {profilePicture && (
                  <ProfilePicture
                    fluid={profilePicture.childImageSharp.fluid}
                  />
                )}
                <Column>
                  <TeamMemberTitle>{name}</TeamMemberTitle>
                  <TeamMemberDescription
                    dangerouslySetInnerHTML={{
                      __html: fixConjunctions(description),
                    }}
                    color="#FFFFFF"
                  />
                </Column>
              </TeamMemberInfoWrapper>
            );
          }
        )}
      </PartnersPageWrapper>
    </>
  );
};

const imagesQuery = graphql`
  {
    members: allFile(filter: { relativeDirectory: { eq: "team" } }) {
      nodes {
        name
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  }
`;

export default TeamPage;
